@import "bootstrap/dist/css/bootstrap.min.css";

* {
  font-family: Red Rose;
  color: white;
  animation: fadeInAnimation ease 2s;
}

@keyframes img {
  0% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
  100% {
    opacity: 1;
    transform: scale(1.7);
    -webkit-transform: scale(1.7);
  }
}

@keyframes wpp {
  20% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes initialimg {
  20% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
input {
  border: none;
}

a {
  text-decoration: none;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
button {
  cursor: pointer;
}

button:hover {
  transform: scale(1.1);
  transition: 1.2s;
}
.insta:hover {
  transform: scale(1.2);
  transition: 2s;
}
.instalar:hover {
  transform: scale(1.1);
  transition: 2s;
}

textarea:focus,
input:focus,
select:focus {
  box-shadow: 0 0 0 0;
  border: 0 none;
  outline: 0;
}
.awssld__box {
  background-color: transparent;
}
.divcarousel {
  background-color: transparent;
}
.iconstext {
  background-color: transparent;
}
.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  font-size: 35px;
  background: black;
  color: white;
  cursor: pointer;
  border-radius: 4px;
  border: none;
  box-shadow: 0 5px 10px #ffffff;
  display: flex;
  align-items: center;

  font-weight: bold;
  height: 6vh;
}
.awssld__bullets {
  gap: 1.5rem;
}

.divhr {
  display: flex;
  justify-content: center;
  width: 1100px;
  height: 0.1vh;
  background-color: black;
}
.bloq {
  width: 180px;
}
.hr {
}
.htext:hover {
  transform: scale(1.2);
  transition: 1.2s;
}
.sticky {
  position: sticky;
  top: 0px;
  z-index: 100;

  animation: fadeInAnimation 1.5s;
  transition: 1.5s;
}

.io {
  width: 100px;
  font-size: 40px;
  background-color: transparent;
}

.divnav {
  width: 100%;
  display: flex;
  gap: 0rem;

  background-color: transparent;
  align-items: center;
  background-color: black;
  height: 8vh;
}
.divlogo {
  display: flex;
  justify-content: center;
  padding-top: 0rem;
  background-color: #000000;
  height: 40vh;
  align-items: center;
}
.divlogoinit {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: #000000;
}
.tlancamento {
  font-size: 100px;
  font-weight: 400;

  color: white;
  cursor: pointer;
  background-color: black;
  letter-spacing: 5px;
  margin-bottom: 0;
}

.divl {
  display: flex;
  justify-content: center;
  background-color: black;
}
.l {
  font-weight: 400;
  letter-spacing: 10px;
  font-size: 150px;

  margin-top: 0;
}

.gifdiv {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: black;
}

.gif {
  width: 900px;
  opacity: 0.5;
  margin-bottom: 5rem;
}
.divlancamento {
  display: flex;
  justify-content: center;
  background-color: #0d0d0d;
  height: 100%;
  align-items: center;
  width: 100%;
}
.divpics {
  display: flex;
  margin-top: 2.5rem;
  gap: 5rem;
  background-color: transparent;
  margin-bottom: 1rem;
}

.pics {
  width: 90px;

  height: 8vh;
  opacity: 0.7;
  cursor: pointer;
  margin-bottom: 2rem;
}
.pics:hover {
  animation: img 2s;
  cursor: pointer;
}

.lancamento {
  display: flex;
  justify-content: center;
  background-color: black;
  padding-top: 4rem;
  gap: 2rem;
}

.titlediv {
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #0d0d0d;
  height: 100%;
}
.titledivp {
  display: flex;
  justify-content: center;

  background-color: #ffffff;
  align-items: center;
  height: 100%;
}
.imglogo1 {
  width: 500px;
  height: 50vh;
}

.imglogo {
  width: 85px;
  background-color: transparent;
  padding-right: 2.5rem;
  animation: initialimg 8s infinite;
}
.divimg {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  background-color: transparent;
}
.htext {
  letter-spacing: 2.5px;
  font-size: 16px;
  font-weight: 600;
  color: white;
  background-color: transparent;
  padding-left: 3rem;
  height: 100%;
  margin-bottom: 0;

  height: 5vh;
  border-radius: 4px;
  width: 200px;
}
.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: transparent;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: transparent;
  --bs-btn-active-border-color: transparent;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: transparent;
}
.dropdown-toggle::after {
  display: none;
}

html
  body
  div#root
  div.App
  nav.sticky
  div.divnav
  div.divselect
  div.show.dropdown
  button#dropdown-basic.toggle.dropdown-toggle.show.btn.btn-success {
  color: black;
}
.dropdown-menu.show {
  margin-left: 0;
  width: 220px;

  text-align: left;
  color: #848484;
  background-color: #545454;
  border: none;

  font-size: 16px;
  font-weight: 600;
  height: 65vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

button#dropdown-basic.toggle.dropdown-toggle.btn.btn-success {
  background-color: #ffffff;

  border-radius: 2px;
  width: 200px;
  color: black;
  transform: none;
  transition: none;
  font-size: 20px;
  letter-spacing: 3px;
  font-weight: 600;
}

.ttext {
  font-size: 90px;
  width: 1250px;
  text-align: center;
  letter-spacing: 1.5px;

  color: white;
  font-weight: 400;
  background-color: transparent;
  height: 100%;
  display: flex;
  align-items: center;
  height: 70vh;
}
.ttextm {
  font-size: 90px;
  width: 1250px;
  text-align: center;
  letter-spacing: 1.5px;

  color: black;
  font-weight: 400;
  background-color: transparent;
  height: 100%;
  display: flex;
  align-items: center;
  height: 70vh;
}
.picpioneer {
  width: 240px;
}
.ledpic {
  width: 205px;
}
.fdivproduct {
  display: flex;
  justify-content: center;
  background-color: white;
  width: 100%;
  height: 100%;
}
.divproduct {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5rem;
  justify-content: center;

  width: 500px;
  height: 100%;
  background-color: transparent;
}
.textproduct {
  text-align: center;
  letter-spacing: 1px;
  font-size: 19px;
  font-weight: 100;
  color: #0d0d0d;
  font-weight: 100;
  margin-top: 0;
  background-color: transparent;
}
.buybtn {
  background: -webkit-linear-gradient(
    to left,

    #595959,
    #262626
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,

    #595959,
    #262626
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  border-radius: 4px;
  width: 280px;
  font-size: 16px;
  height: 6vh;

  border: none;

  font-weight: 600;
  letter-spacing: 2.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin-bottom: 3rem;
  color: whitesmoke;
}

.buybtnf {
  width: 280px;
  font-size: 16px;
  height: 6vh;
  border: none;
  background: -webkit-linear-gradient(
    to left,

    #595959,
    #262626
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,

    #595959,
    #262626
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  border-radius: 4px;
  font-weight: 600;
  letter-spacing: 2.5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.buy {
  font-size: 34px;
  color: #0d0d0d;
  background-color: transparent;

  font-weight: 500;
  letter-spacing: 2px;
  height: 2.5vh;
  margin-bottom: 2rem;
}
.rs {
  background-color: transparent;
}
nav.awssld__bullets {
  display: none;
}
.descrip {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1.5rem;
}

.h3descrip {
  font-size: 14px;

  color: black;
  text-align: left;
  background-color: black;
  color: white;
  height: 5vh;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 450px;
  border-radius: 2px;
  gap: 0.5rem;
  font-weight: 400;

  letter-spacing: 1px;
  border-radius: 4px;
}
.descripbold {
  font-size: 15px;

  letter-spacing: 1px;
  font-weight: 600;
  margin-bottom: 0;
}

.divpromo {
  display: flex;
  justify-content: center;
  background-color: #0d0d0d;
  height: 100%;
  align-items: center;
}
.divpromop {
  display: flex;
  justify-content: center;
  background-color: #0d0d0d;
  height: 100%;
  align-items: center;
  margin-top: 1.5rem;
}
.promotext {
  font-size: 42px;
  color: white;
  font-weight: 400;
  letter-spacing: 2px;
  background-color: transparent;
  height: 10vh;
  display: flex;
  align-items: center;
  text-align: center;

  margin-bottom: 0;
}

.btnpromo {
  width: 280px;
  height: 5vh;
  border: none;
  background: -webkit-linear-gradient(
    to left,

    #595959,
    #262626
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,

    #595959,
    #262626
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  border-radius: 4px;
  font-weight: 600;

  font-size: 16px;
  letter-spacing: 3px;
  margin-top: 1rem;
}
.email {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 2px;
  margin-bottom: 0;
  width: 300px;
  text-align: center;
  background-color: black;
}

.allinfo {
  background-color: black;
}

img {
  background-color: transparent;
}

.divinfo {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.divbtnpromo {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding-top: 2rem;
  padding-bottom: 5rem;

  background-color: black;
}
.hrpromo {
  height: 0.3vh;
  width: 280px;
  background-color: white;
  border: none;
  margin-top: 0;
}

.divinfo {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
}
.infos {
  font-size: 19px;
  letter-spacing: 2px;
  color: white;
  font-weight: 400;
  padding-top: 1rem;
}

.h2insta {
  margin-top: 0;
}
.insta {
  width: 42px;
  color: #8c8c8c;
  margin-bottom: 1.5rem;
  cursor: pointer;
  opacity: 0.6;
  margin-top: 0.3rem;
}
.insta:hover {
  opacity: 1;
}

.wpp {
  color: #0d0d0d;
  background-color: transparent;
  font-size: 26px;
  animation: wpp 3s infinite;
}
.btnwpp {
  width: 230px;
  height: 5vh;
  border: none;
  background-color: #25d366;
  border-radius: 4px;

  font-size: 16px;
  letter-spacing: 3px;
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 0.7rem;
  font-weight: 600;
  margin-top: 0.3rem;
}

.divcarousel {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.h3carousel {
  gap: 2rem;
  animation: none;
  margin-top: 0;
}
.divfullc {
  height: 100%;
  background-color: black;
}

.awssld {
  height: 35vh;
  color: white;
}
.awssld__content {
  --content-background-color: transparent;
}
.divproducts {
  display: flex;
  justify-content: center;
  background-color: #0d0d0d;
  height: 100%;
  align-items: center;
}
.producttext {
  font-size: 42px;
  color: white;
  font-weight: 400;
  letter-spacing: 2.5px;
  background-color: transparent;
  height: 5vh;
  display: flex;
  align-items: center;
  text-align: center;
  text-decoration: none;

  border: none;
  height: 10vh;
  cursor: pointer;
}
.icons {
  width: 100px;
}
.icons1 {
  width: 65px;
}

.icons2 {
  width: 90px;
}
.iconstext {
  font-size: 32px;
  font-weight: 300;
  letter-spacing: 3px;
  text-align: center;
  padding-top: 2rem;
}
.link {
  text-decoration: none;
  background-color: transparent;
}

.fdivproductp {
  background-color: white;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.divproductp {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  height: 100%;

  background-color: transparent;
}
.divproductpf {
  display: flex;
  flex-direction: column;
  align-items: center;

  justify-content: center;

  width: 600px;
  height: 70vh;

  background-color: transparent;
}
.textproductp {
  text-align: center;
  letter-spacing: 1px;
  font-size: 19px;

  color: #0d0d0d;
  font-weight: 500;

  background-color: transparent;
  width: 450px;
  margin-top: 3rem;
  line-height: 3vh;

  margin-bottom: 1.5rem;
}
.alarme {
  width: 230px;
  height: 19vh;
}
.pe {
  width: 180px;
}

.instalar {
  width: 340px;
  height: 6vh;
  background-color: #ff2800;
  color: white;

  font-size: 17px;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  margin-top: 1rem;
  letter-spacing: 1.5px;
}

.direitos {
  display: flex;
  justify-content: center;
  font-size: 11px;
  letter-spacing: 2.5px;

  color: white;
  margin-top: 4rem;
  padding-bottom: 0.2rem;
}
.toggle {
  display: flex;
  align-items: center;
  justify-content: center;
}
.dropmenu {
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1px;
  color: #c3c3c3;
}
.dropdown-menu {
  width: 210px;
  height: 35vh;
  margin: 0.5rem;
}

a.dropmenu.dropdown-item {
  text-align: left;
  font-size: 14px;

  font-weight: 400;
  background-color: transparent;
  border-radius: 0px;
  letter-spacing: 2px;
}

.picpioneer1 {
  width: 145px;
}
.picpioneer2 {
  width: 150px;
}
.picpioneer3 {
  width: 220px;
}
.picpioneer4 {
  width: 150px;
}
.picpioneer5 {
  width: 180px;
}
.picpioneer6 {
  width: 180px;
}
.picpioneer7 {
  width: 160px;
}
.alarme1 {
  width: 135px;
}
.alarme2 {
  width: 170px;
}
.caixa {
  width: 170px;
}
.cam {
  width: 180px;
}
.convers {
  width: 240px;
}
.esp {
  width: 190px;
}
.f1 {
  width: 180px;
}
.f2 {
  width: 190px;
}
.f3 {
  width: 180px;
}
.f4 {
  width: 170px;
}
.f5 {
  width: 170px;
}
.f6 {
  width: 185px;
}
.f7 {
  width: 165px;
}
.fjb {
  width: 190px;
}
.f8 {
  width: 160px;
}
.f9 {
  width: 135px;
}
.f10 {
  width: 170px;
}
.t1 {
  width: 190px;
}
.t2 {
  width: 155px;
}
.lamp1 {
  width: 180px;
}
.lamp2 {
  width: 165px;
}
.lamp3 {
  width: 150px;
}
.lamp4 {
  width: 175px;
}
.mod1 {
  width: 180px;
  height: 17vh;
}
.mod2 {
  width: 180px;
  height: 17vh;
}
.video {
  width: 100%;
}
.multi1 {
  width: 200px;
}
.multi2 {
  width: 240px;
}
.multi3 {
  width: 235px;
}
.radio2 {
  width: 250px;
}
.sensor1 {
  width: 170px;
}
.tapetes1 {
  width: 160px;
}
.tomadas1 {
  width: 120px;
}

@media screen and (max-width: 1500px) {
  .fdivproduct {
    flex-direction: column;

    align-items: center;
  }
  .divproduct {
    margin: 2.5rem;
  }

  .gif {
    width: 700px;
  }
  .tlancamento {
    font-size: 70px;
  }

  .l {
    font-size: 110px;
  }
  .divhr {
    width: 850px;
  }
  .ttext {
    font-size: 80px;
    width: 1100px;
  }
  .ttextm {
    font-size: 80px;
    width: 1100px;
  }
}

@media screen and (max-width: 1400px) {
  .ttext {
    font-size: 60px;
    width: 1000px;
    line-height: 10vh;
  }
  .ttextm {
    font-size: 60px;
    width: 1000px;
    line-height: 10vh;
  }
  .promotext {
    font-size: 35px;
  }
}
@media screen and (max-width: 1366px) and (max-height: 768px) {
  div.dropdown-menu.show {
    height: 80vh;
  }
}

@media screen and (max-width: 1200px) {
  .ttext {
    font-size: 52px;
    width: 900px;
  }
  .ttextm {
    font-size: 52px;
    width: 900px;
  }
  .tlancamento {
    font-size: 60px;
  }

  .l {
    font-size: 90px;
  }
  .divhr {
    width: 750px;
  }
  .iconstext {
    font-size: 24px;
  }
  .promotext {
    font-size: 31px;
  }
}
@media screen and (max-width: 1100px) {
  .promotext {
    font-size: 28px;
  }
}
@media screen and (max-width: 1000px) {
  .gif {
    width: 500px;
    margin-bottom: 2.5rem;
  }
  .tlancamento {
    font-size: 50px;
  }

  .l {
    font-size: 75px;
  }
  .divhr {
    width: 650px;
  }
  .lancamento {
    gap: 1.5rem;
  }
  .ttext {
    font-size: 40px;
    width: 700px;
    line-height: 7vh;
  }
  .ttextm {
    font-size: 40px;
    width: 700px;
    line-height: 7vh;
  }
  .promotext {
    font-size: 26px;
    font-weight: 400;
  }
  .producttext {
    font-size: 34px;
    font-weight: 400;
  }
}
@media screen and (max-width: 900px) {
  .imglogo1 {
    width: 430px;
  }
  .divhr {
    width: 550px;
  }
  .promotext {
    font-size: 24px;
  }
}
@media screen and (max-width: 800px) {
  .ttext {
    letter-spacing: 1px;
    font-size: 36px;
    width: 600px;
  }
  .ttextm {
    letter-spacing: 1px;
    font-size: 36px;
    width: 600px;
  }
  .tlancamento {
    font-size: 40px;
  }
  .promotext {
    font-size: 32px;
    width: 500px;
    height: 15vh;
  }

  .l {
    font-size: 60px;
  }
}
@media screen and (max-width: 700px) {
  .producttext {
    font-size: 26px;
  }
  .textproductp {
    width: 400px;
    font-size: 16px;
  }
  .textproduct {
    width: 400px;
    font-size: 16px;
  }
  .promotext {
    font-size: 26px;
  }

  .gif {
    width: 400px;
  }
  .tlancamento {
    font-size: 35px;
  }

  .l {
    font-size: 50px;
  }
  .divhr {
    width: 400px;
  }
}
@media screen and (max-width: 600px) {
  .tlancamento {
    font-size: 30px;
  }

  .l {
    font-size: 50px;
    letter-spacing: 10px;
  }
}
@media screen and (max-width: 500px) {
  .divlogo {
    height: 30vh;
  }

  a.dropmenu.dropdown-item {
    height: 2.9vh;

    border: none;
    border-radius: 0;
  }
  .h3descrip {
    width: 370px;
    font-size: 12px;
  }
  .descripbold {
    font-size: 12px;
    letter-spacing: 0.1px;
  }
  .instalar {
    width: 310px;
    font-size: 16px;
  }
  .ttext {
    width: 400px;
    font-size: 28px;
    height: 35vh;
    font-weight: 400;
    letter-spacing: 2px;
    line-height: 5.5vh;
  }
  .ttextm {
    width: 400px;
    font-size: 28px;
    height: 35vh;
    font-weight: 400;
    letter-spacing: 2px;
    line-height: 5.5vh;
  }
  .iconstext {
    width: 400px;
    font-size: 16px;
    letter-spacing: 0.5px;
    font-weight: 400;
  }
  .imglogo1 {
    width: 380px;
    height: 40vh;
  }

  .divproduct {
    width: 100%;
  }

  .divpics {
    gap: 2.3rem;
  }
  .l {
    font-size: 40px;
  }
  .io {
    width: 80px;
  }
  .imglogo {
    padding-right: 1rem;
    width: 45px;
  }
  .divhr {
    width: 350px;
  }

  div.dropdown-menu.show {
    width: 190px;
    height: 60vh;
    padding-top: 0.2rem;
  }
  html
    body
    div#root
    div
    nav
    div.divnav
    div.divselect
    div.dropdown
    button#dropdown-basic.toggle.dropdown-toggle.btn.btn-success {
    background-color: #ffffff;

    height: 4vh;

    width: 150px;
    color: black;
    transform: none;
    transition: none;
    font-size: 13px;
  }
  .back-to-top {
    font-size: 28px;
    height: 5vh;
    right: 10px;
    bottom: 10px;
  }
  .textproductp {
    width: 380px;
    font-size: 15px;
  }
  .textproduct {
    width: 380px;
    font-size: 15px;
  }

  .promotext {
    font-size: 22px;
    width: 350px;
    line-height: 3vh;
    letter-spacing: 1px;
    font-weight: 500;
  }
  .producttext {
    font-size: 17px;

    font-weight: 500;
    letter-spacing: 1px;
    height: 12vh;
    width: 320px;
  }

  .icons {
    width: 80px;
    margin-top: 1rem;
  }
  .icons1 {
    width: 55px;
    margin-top: 1rem;
  }
  .icons2 {
    width: 70px;
    margin-top: 1rem;
  }
  .buybtn {
    letter-spacing: 1.5px;
    font-size: 15px;
  }
  .buybtnf {
    letter-spacing: 1.5px;
    font-size: 15px;
  }
  .btnpromo {
    font-size: 15px;
    letter-spacing: 2.5px;
  }
}

@media screen and (max-width: 430px) {
  .htext {
    font-size: 13px;
    padding-left: 2rem;
  }

  .ttext {
    font-size: 24px;
    width: 370px;
    line-height: 4.5vh;
  }
  .ttextm {
    font-size: 24px;
    width: 370px;
    line-height: 4.5vh;
  }
  .textproductp {
    width: 330px;
    font-size: 14px;
  }
  .textproduct {
    width: 330px;
    font-size: 14px;
  }
  .imglogo1 {
    width: 320px;
    height: 35vh;
  }

  .tlancamento {
    width: 350px;
    text-align: center;
    font-size: 28px;
  }

  .lancamento {
    gap: 0;
  }

  .l {
    letter-spacing: 8px;
  }
  .gif {
    width: 320px;
  }
  .divhr {
    width: 310px;
  }
  .email {
    font-size: 16px;
  }

  .picpioneer {
    width: 180px;
    margin-top: 1.5rem;
  }
  .ledpic {
    width: 150px;
    margin-top: 1.5rem;
  }

  .divpics {
    gap: 2.8rem;
  }
  .pics {
    width: 80px;
    height: 8vh;
  }
}
@media screen and (max-width: 414px) {
  .ttext {
    width: 350px;

    height: 40vh;
  }
  .ttextm {
    width: 350px;

    height: 40vh;
  }
  .iconstext {
    width: 350px;
  }
  .l {
    letter-spacing: 6px;
  }
}
@media screen and (max-width: 412px) {
  .promotext {
    font-size: 18px;
  }
}
@media screen and (max-width: 390px) {
  .textproduct {
    width: 300px;
  }
  .textproductp {
    width: 300px;
  }
  .tlancamento {
    font-size: 25px;
  }

  .l {
    font-size: 35px;
  }

  .h3descrip {
    width: 350px;
  }
  .instalar {
    width: 280px;
    font-size: 15px;
    letter-spacing: 1px;
  }
}
@media screen and (max-width: 384px) {
  .imglogo1 {
    width: 280px;
    height: 30vh;
  }
  .btnpromo {
    height: 6vh;
  }
  .btnwpp {
    height: 6vh;
  }
}
@media screen and (max-width: 375px) {
  .h3descrip {
    width: 330px;
    font-size: 11px;
  }
  .descripbold {
    font-size: 11px;
  }
  .l {
    letter-spacing: 5px;
  }
  .tlancamento {
    letter-spacing: 4px;
    width: 300px;
  }

  .imglogo1 {
    width: 290px;
    height: 30vh;
  }
  .divpics {
    gap: 2.5rem;
  }
  .pics {
    width: 60px;
    height: 6vh;
  }
  .back-to-top {
    height: 5.5vh;
  }

  .iconstext {
    width: 320px;
  }
  .textproduct {
    width: 290px;
    font-size: 13px;
  }
  .textproductp {
    width: 290px;
    font-size: 13px;
  }

  .producttext {
    font-size: 16px;
  }

  div.dropdown-menu.show {
    height: 60vh;
  }
}
@media screen and (max-width: 360px) {
  .h3descrip {
    width: 310px;

    letter-spacing: 0px;
  }
  .descripbold {
    font-size: 10px;
  }

  .ttext {
    width: 320px;
    font-size: 20px;
    line-height: 4vh;
  }
  .ttextm {
    width: 320px;
    font-size: 20px;
    line-height: 4vh;
  }
  .back-to-top {
    height: 6vh;
  }
  .imglogo1 {
    width: 270px;
    height: 28vh;
  }

  .divhr {
    width: 270px;
  }
  .buybtn {
    height: 6vh;
  }
  .buybtnf {
    height: 6vh;
  }
  .btnpromo {
    height: 6vh;
  }
  .btnwpp {
    height: 6vh;
  }

  .iconstext {
    width: 280px;
    font-size: 14px;
  }
  .producttext {
    font-size: 15px;
  }
  div.dropdown-menu.show {
    height: 61vh;
  }
  .textproduct {
    letter-spacing: 0.5px;
  }
  .textproductp {
    letter-spacing: 0.5px;
  }
}
@media screen and (max-width: 360px) and (max-height: 640px) {
  .imglogo1 {
    height: 42vh;
  }
  .ttext {
    height: 38vh;
    width: 320px;
  }
  .ttextm {
    height: 38vh;
    width: 320px;
  }
  .btnwpp {
    height: 7vh;
  }
}
@media screen and (max-width: 330px) {
  .l {
    font-size: 27px;
  }
  .tlancamento {
    font-size: 20px;
  }
  .divhr {
    width: 230px;
  }
  .h3descrip {
    font-size: 11px;
    width: 280px;
  }

  .back-to-top {
    height: 6.5vh;
  }
  .producttext {
    width: 310px;
  }
}
@media screen and (max-width: 320px) {
  .textproduct {
    width: 270px;
  }
  .textproductp {
    width: 270px;
  }

  .ttext {
    width: 290px;
    font-size: 21px;
  }
  .ttextm {
    width: 290px;
    font-size: 21px;
  }
  .producttext {
    font-size: 15px;
    font-weight: 500;
  }
  .instalar {
    width: 250px;
    font-size: 14px;
    letter-spacing: 1px;
  }

  .promotext {
    font-size: 16px;
  }

  .l {
    letter-spacing: 3px;
    font-size: 32px;
  }
  .tlancamento {
    font-size: 21px;
  }

  .email {
    width: 250px;
  }
  .hrpromo {
    width: 240px;
  }
  .btnpromo {
    width: 250px;
    font-size: 14px;
  }
  .btnwpp {
    font-size: 14px;
    width: 210px;
  }
  .iconstext {
    font-size: 14px;
  }

  .pics {
    width: 50px;
  }
  .buybtn {
    width: 240px;
    font-size: 13px;
  }
  .h3descrip {
    font-size: 10px;
    letter-spacing: 0;
  }
}
